<template>
  <div v-if="!isGiftCard && cartStore.freeShippingEnabled" class="free-shipping-threshold">
    <div
      :class="['freeshipping-notice flex-inline', { 'shipping-notice-flex-wrap': !cartStore.freeShippingThresholdMet }]"
    >
      <p
        v-if="cartStore.freeShippingProductInCart || cartStore.freeShippingThresholdMet"
        :class="['shipping-msg', { 'shipping-eligible': !cartStore.freeShippingThresholdMet }]"
      >
        Congrats, you're eligible for
        <b>{{ cartStore.freeShippingProductInCart ? 'Free Shipping' : cartStore.freeShippingReachedMessaging }}</b>
      </p>
      <template v-if="!cartStore.freeShippingProductInCart">
        <div v-if="!cartStore.freeShippingThresholdMet" class="shipping-msg">
          {{ cartStore.freeShippingMessagingATC }}
        </div>
      </template>
      <TruckLine />
    </div>
    <div class="freeshipping-bar-container flex">
      <div
        class="freeshipping-bar"
        :style="`width: ${cartStore.freeShippingProductInCart ? 100 : cartStore.freeShippingPercent}%`"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useCartStore } from '~/store/cart';

const cartStore = useCartStore();

const isGiftCard = computed<boolean>(() => {
  return cartStore.latestAdditions?.length && !!cartStore.latestAdditions?.[0]?.product?.gift_certificates?.length;
});
</script>

<style lang="scss" scoped>
.free-shipping-threshold {
  margin-bottom: 1rem;
  .shipping-eligible {
    width: 100%;
  }
  .shipping-msg {
    margin-bottom: 0;
    margin-right: 5px;
    + .shipping-msg {
      margin-top: 0.625rem;
      margin-bottom: #{local-functions.rem-calc(5)};
    }
  }
  .freeshipping-notice {
    margin-bottom: 2px;

    &.shipping-notice-flex-wrap {
      flex-wrap: wrap;
    }
  }
  .freeshipping-bar-container {
    border-radius: 6px;
    background-color: $color-neutral-cool-100;
    width: 100%;
    height: 6px;
    .freeshipping-bar {
      background-color: $color-primary-500;
      border-radius: 6px;
    }
  }
}
</style>
